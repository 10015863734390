var $jscomp = $jscomp || {};
$jscomp.scope = {};
$jscomp.createTemplateTagFirstArg = function (b) {
    return (b.raw = b);
};
$jscomp.createTemplateTagFirstArgWithRaw = function (b, a) {
    b.raw = a;
    return b;
};
var CSVBoxImporter = function (b, a, c, d) {
    a = void 0 === a ? {} : a;
    c = void 0 === c ? null : c;
    this.configuration = d = void 0 === d ? {} : d;
    this.log('Importer initialisation started');
    this.shouldOpenModalonIframeLoad = this.isIframeLoaded = !1;
    this.slug = b;
    this.data = a;
    this.key = this.randomString();
    this.columns = [];
    this.options = [];
    this.configuration.data_location && (this.data_location = this.configuration.data_location);
    c && 'function' == typeof c && (this.callback = c);
    var f = this;
    'complete' === document.readyState && (f.log('document readyState is complete'), f.configuration.lazy || f.setUpImporter());
    this.log('Setting up DOMContentLoaded event listener ' + document.readyState);
    document.addEventListener('DOMContentLoaded', function () {
        f.log('Event: DOMContentLoaded');
        f.configuration.lazy || f.setUpImporter();
    });
    this.configuration.lazy || this.setUpImporter();
    this.log('Importer initialisation done');
};
CSVBoxImporter.prototype.initImporter = function () {
    try {
        this.log('Called setUpImporter();');
        this.isIframeLoaded = this.isModalShown = !1;
        this.setupMessageListener();
        var b = document.createElement('style');
        b.type = 'text/css';
        'textContent' in b
            ? (b.textContent =
                  '.csvbox-component {position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index:2147483647;}.csvbox-component iframe{height: 100%;width: 100%;position: absolute;}')
            : (b.innerText =
                  '.csvbox-component {position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index:2147483647;}.csvbox-component iframe{height: 100%;width: 100%;position: absolute;}');
        document.body.appendChild(b);
        this.id = 'csvbox-embed-' + this.key;
        this.holder = document.createElement('div');
        this.holder.classList.add('csvbox-component');
        this.holder.style.display = 'none';
        var a = document.createElement('iframe');
        this.iframe = a;
        b = 'app.csvbox.io';
        this.configuration.customDomain && (b = this.configuration.customDomain);
        var c = 'https://' + b + '/embed/';
        this.data_location && (c = 'https://' + this.data_location + '-' + b + '/embed/');
        var d = c + this.slug,
            f;
        d += '?debug=' + !(null == (f = this.configuration) || !f.debug);
        d += '&source=embedCode';
        var k, l;
        d +=
            '&library-version=' +
            ((null == (k = this.configuration) ? 0 : k.libraryVersion) ? (null == (l = this.configuration) ? void 0 : l.libraryVersion) : '1.1.0');
        var g;
        if (null == (g = this.configuration) ? 0 : g.framework) d += '&framework=' + this.configuration.framework;
        this.data_location && (d += '&preventRedirect');
        this.options.language && (d += '&language=' + this.options.language);
        this.log('Loading url ' + d);
        a.setAttribute('src', d);
        a.setAttribute('data-csvbox-slug', this.slug);
        a.frameBorder = 0;
        this.holder.id = this.id;
        this.holder.appendChild(a);
        document.body.appendChild(this.holder);
        var e = this;
        a.onload = function () {
            e.isIframeLoaded = !0;
            e.shouldOpenModalonIframeLoad && ((e.shouldOpenModalonIframeLoad = !1), e.openModal());
            a.contentWindow.postMessage(
                {
                    customer: e.data,
                    columns: e.columns,
                    options: e.options,
                    unique_token: e.key
                },
                '*'
            );
            var h;
            null == (h = e.onReady) || h.call(e);
            e.log('iframe is ready.');
        };
    } catch (h) {
        this.log('Importer initialisation error ' + h);
    }
};
CSVBoxImporter.prototype.setUpImporter = function () {
    var b = document.getElementById('csvbox-embed-' + this.key);
    b
        ? (this.log('Old csvbox component present on page'),
          b.firstChild.getAttribute('data-csvbox-slug') !== this.slug &&
              (this.log('Slug has been changed, csvbox component refresh is required'), b.remove(), this.initImporter()))
        : this.initImporter();
};
CSVBoxImporter.prototype.setUser = function (b) {
    this.data = b;
    this.iframe && this.iframe.contentWindow.postMessage({customer: this.data}, '*');
};
CSVBoxImporter.prototype.listen = function (b, a) {
    a = void 0 === a ? null : a;
    if ('function' == typeof a)
        switch (b) {
            case 'onReady':
                this.onReady = a;
                break;
            case 'onClose':
                this.onClose = a;
                break;
            case 'onSubmit':
                this.onSubmit = a;
        }
};
CSVBoxImporter.prototype.setupMessageListener = function () {
    var b = this;
    window.addEventListener(
        'message',
        function (a) {
            if ('mainModalHidden' === a.data) {
                b.holder.style.display = 'none';
                b.isModalShown = !1;
                var c;
                null == (c = b.onClose) || c.call(b);
            }
            'uploadSuccessful' === a.data && b.callback && 'function' == typeof b.callback && b.callback(!0);
            'uploadFailed' === a.data && b.callback && 'function' == typeof b.callback && b.callback(!1);
            if ('object' == typeof a.data) {
                var d, f;
                if ((null == a ? void 0 : null == (d = a.data) ? void 0 : null == (f = d.data) ? void 0 : f.unique_token) == b.key)
                    if (a.data.type && 'data-on-submit' == a.data.type) {
                        c = a.data.data;
                        c.column_mappings = a.data.column_mapping;
                        delete c.unique_token;
                        var k;
                        null == (k = b.onSubmit) || k.call(b, c);
                    } else if (a.data.type && 'data-push-status' == a.data.type)
                        if ('success' == a.data.data.import_status) {
                            var l;
                            if (null == a ? 0 : null == (l = a.data) ? 0 : l.row_data) {
                                var g = a.data.headers,
                                    e = [],
                                    h = a.data.dynamicColumnsIndexes,
                                    v = a.data.virtualColumnsIndexes || [];
                                a.data.row_data.forEach(function (q) {
                                    var m = {},
                                        r = {},
                                        t = {};
                                    q.data.forEach(function (n, p) {
                                        void 0 == n && (n = '');
                                        h.includes(p) ? (r[g[p]] = n) : v.includes(p) ? (t[g[p]] = n) : (m[g[p]] = n);
                                    });
                                    if (null == q ? 0 : q.unmapped_data) m._unmapped_data = q.unmapped_data;
                                    r && 0 < Object.keys(r).length && (m._dynamic_data = r);
                                    t && 0 < Object.keys(t).length && (m._virtual_data = t);
                                    e.push(m);
                                });
                                a = a.data.data;
                                a.rows = e;
                                delete a.unique_token;
                                b.callback(!0, a);
                            } else (a = a.data.data), delete a.unique_token, b.callback(!0, a);
                        } else (a = a.data.data), delete a.unique_token, b.callback(!1, a);
                    else if (a.data.type && 'csvbox-upload-failed' == a.data.type) b.callback(!1);
                    else if (a.data.type && 'csvbox-modal-hidden' == a.data.type) {
                        b.holder.style.display = 'none';
                        b.isModalShown = !1;
                        var u;
                        null == (u = b.onClose) || u.call(b);
                    }
            }
        },
        !1
    );
    this.log('Message listener initialised.');
};
CSVBoxImporter.prototype.openModal = function () {
    this.configuration.lazy && this.setUpImporter();
    this.isIframeLoaded
        ? this.isModalShown ||
          ((this.isModalShown = !0),
          this.holder.querySelector('iframe').contentWindow.postMessage('openModal', '*'),
          (this.holder.style.display = 'block'))
        : ((this.shouldOpenModalonIframeLoad = !0), this.log('iframe not loaded yet. Modal will open once iframe is loaded'));
};
CSVBoxImporter.prototype.randomString = function () {
    for (var b = '', a = 0; 15 > a; a++) b += 'abcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(36 * Math.random()));
    return b;
};
CSVBoxImporter.prototype.setDynamicColumns = function (b) {
    this.columns = b;
    this.iframe && this.iframe.contentWindow.postMessage({columns: this.columns}, '*');
};
CSVBoxImporter.prototype.setOptions = function (b) {
    this.options = b;
    this.iframe && this.iframe.contentWindow.postMessage({options: this.options}, '*');
};
CSVBoxImporter.prototype.log = function (b) {
    (this.configuration.debug || (sessionStorage && 'true' == sessionStorage.getItem('CSVBOX_DEBUG_FLAG'))) && console.log('[CSVBox]', b);
};
null != document.querySelector('[data-csvbox]') &&
    (document.onreadystatechange = function () {
        'complete' === document.readyState
            ? (document.querySelector('[data-csvbox]').disabled = !1)
            : (document.querySelector('[data-csvbox]').disabled = !0);
    });
